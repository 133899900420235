import * as actionTypes from 'src/actions'

const initialState = {
  open: false,
  message: '',
  type: 'success',
}

const snackBarReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SNACKBAR: {
      const { open, status, message } = action
      return {
        open,
        status,
        message,
      }
    }
    default:
      return state
  }
}

export default snackBarReducer
