import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Typography, Grid, Button } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
  header: {
    width: theme.breakpoints.values.md,
    maxWidth: '100%',
    margin: '0 auto',
    padding: '80px 24px',
    [theme.breakpoints.up('md')]: {
      padding: '160px 24px',
    },
  },
  buttons: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
  mediaContainer: {
    margin: '0 auto',
    maxWidth: 1600,
    padding: theme.spacing(0, 2),
    overflow: 'hidden',
  },
  media: {
    width: '100%',
  },
  stats: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1),
  },
  statsInner: {
    width: theme.breakpoints.values.md,
    maxWidth: '100%',
    margin: '0 auto',
  },
  ardentLink: {
    color: '#fff',
    textDecoration: 'underline',
  },
}))

function Header({ className, ...rest }) {
  const classes = useStyles()

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.header}>
        <Typography align="center" gutterBottom variant="h1">
          Ardent College Counseling
        </Typography>
        <Typography align="center" component="h2" variant="subtitle1">
          All students have the potential to attend their dream college but not
          all of them know what steps to take towards success, especially with
          shifting trends in college admissions. In such situations, Ardent
          College Counseling is here for you! Ardent Academy offers an extensive
          College Counseling program that focuses on mentoring students and
          families to achieve college application success as early as the 7th
          grade.
        </Typography>
        <div className={classes.buttons}>
          <Button
            color="primary"
            component="a"
            href="https://ardentacademy.com"
            target="_blank"
            variant="contained"
          >
            Ardent Academy
          </Button>
        </div>
      </div>
      <div className={classes.mediaContainer}>
        <img
          alt="Demos"
          className={classes.media}
          src="/images/presentation/header.jpg"
        />
      </div>
      <div className={classes.stats}>
        <Grid
          alignItems="center"
          className={classes.statsInner}
          container
          justify="center"
          spacing={3}
        >
          <Grid item lg={3} md={6} xs={12}>
            <Typography color="inherit" gutterBottom variant="h3">
              14+
            </Typography>
            <Typography color="inherit" variant="body2">
              Counseling Services
            </Typography>
          </Grid>
          <Grid item lg={3} md={6} xs={12}>
            <Typography color="inherit" gutterBottom variant="h3">
              Top 30
            </Typography>
            <Typography color="inherit" variant="body2">
              Universities
            </Typography>
          </Grid>
          <Grid item lg={3} md={6} xs={12}>
            <Typography color="inherit" gutterBottom variant="h3">
              2 FREE
            </Typography>
            <Typography color="inherit" variant="body2">
              <a
                className={classes.ardentLink}
                target="_blank"
                rel="noopener noreferrer"
                href="https://ardentacademy.com/index.php/college-counseling"
              >
                Months of College Counseling
              </a>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

Header.propTypes = {
  className: PropTypes.string,
}

export default Header
