import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Container, Grid, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  media: {
    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
}))

function PluginsSupport({ className, ...rest }) {
  const classes = useStyles()

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Container maxWidth="lg">
        <Grid alignItems="center" container justify="space-between">
          <Grid item lg={4} md={5} xs={12}>
            <Typography gutterBottom variant="h3">
              Personalized Guidance for Every Student
            </Typography>
            <Typography variant="subtitle2">
              Ardent College Counseling provides personalized guidance so that
              every student's needs are heard and supported. Ardent Counseling
              offers services such as academic planning, course selections, GPA
              management, test preparation, college essay planning and editing,
              and much, much more!
            </Typography>
          </Grid>
          <Grid className={classes.media} item lg={4} md={6} xs={12}>
            <img alt="User flows" src="/images/presentation/counseling.svg" />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

PluginsSupport.propTypes = {
  className: PropTypes.string,
}

export default PluginsSupport
