import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'
import { makeStyles, useTheme } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
}))

function Chart({ className, data: dataProp, labels, ...rest }) {
  const classes = useStyles()
  const theme = useTheme()

  const data = {
    datasets: [
      {
        data: dataProp,
        backgroundColor: theme.palette.primary.main,
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
      },
    ],
    labels,
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cornerRadius: 20,
    legend: {
      display: false,
    },
    layout: {
      padding: 0,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider,
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
            maxTicksLimit: 5,
            callback: value => (value > 0 ? `${value} users` : value),
          },
        },
      ],
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      caretSize: 10,
      yPadding: 20,
      xPadding: 20,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.common.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
      callbacks: {
        title: () => {},
        label: tooltipItem => {
          let label = `Number of Users: ${tooltipItem.yLabel}`

          return label
        },
      },
    },
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Bar data={data} options={options} />
    </div>
  )
}

Chart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
}

export default Chart
