import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Page from 'src/components/Page'
import Header from './Header'
import FAQ from './FAQ'
import Guidance from './Guidance'
import SuccessfulResults from './SuccessfulResults'
import PathSuccess from './PathSuccess'

const useStyles = makeStyles(() => ({
  root: {},
}))

function Presentation() {
  const classes = useStyles()

  return (
    <Page className={classes.root} title="Presentation">
      <Header />
      <PathSuccess />
      <Guidance />
      <SuccessfulResults />
      <FAQ />
    </Page>
  )
}

export default Presentation
