import React from 'react'
import { Router } from 'react-router-dom'
import { renderRoutes } from 'react-router-config'
import { createBrowserHistory } from 'history'
import MomentUtils from '@date-io/moment'
import { Provider as StoreProvider } from 'react-redux'
import { ThemeProvider } from '@material-ui/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { theme } from './theme'
import { configureStore } from './store'
import routes from './routes'
import GoogleAnalytics from './components/GoogleAnalytics'
import CookiesNotification from './components/CookiesNotification'
import ScrollReset from './components/ScrollReset'
import StylesProvider from './components/StylesProvider'
import './mixins/chartjs'
import './mixins/moment'
import './mixins/validate'
import './mixins/prismjs'
import './mock'
import './assets/scss/main.scss'

import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'

import introspectionQueryResultData from 'src/utils/fragmentTypes.json'
import { apiUrl, tokenName } from './config'
import { redirect } from './layouts/Dashboard/TopBar'

import GlobalSnackbar from 'src/components/GlobalSnackbar'
import AuthGuard from 'src/components/AuthGuard'

// To inform the client about the relationship between User and its subtypes
// https://jiga.dev/react-how-to-use-apollo-with-union-types-in-graphql/
// https://www.apollographql.com/docs/react/v2/data/fragments/
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})

const httpLink = createHttpLink({
  uri: `${apiUrl}/graphql`,
})

const regex = new RegExp(`${tokenName}=([\\w.-]*)`)

const getToken = () => {
  const cookies = document.cookie.toString()

  // Make sure there is a valid token, else return null
  return regex.exec(cookies) ? regex.exec(cookies)[1] : null
}

let token
const authLink = setContext((_, { headers }) => {
  if (!token) token = getToken()

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const resetToken = onError(({ networkError }) => {
  if (
    networkError &&
    networkError.name === 'ServerError' &&
    networkError.statusCode === 401
  ) {
    // remove cached token on 401 from the server
    redirect()
  }
})

export const client = new ApolloClient({
  link: authLink.concat(resetToken).concat(httpLink),
  cache: new InMemoryCache({
    fragmentMatcher,
  }),
})

const saveSessionState = session => {
  try {
    const serializedSession = JSON.stringify(session)
    localStorage.setItem('ardentSession', serializedSession)
  } catch (err) {
    console.log('Failed to save item to local storage.')
  }
}

const loadSessionState = () => {
  try {
    const serialized = localStorage.getItem('ardentSession')
    const token = getToken()

    if (serialized === null || !token) {
      return {
        loggedIn: false,
        user: {
          role: 'GUEST',
        },
      }
    }
    return JSON.parse(serialized)
  } catch (err) {
    return {
      loggedIn: false,
      user: {
        role: 'GUEST',
      },
    }
  }
}

const persistedSession = loadSessionState()
const history = createBrowserHistory()
const store = configureStore(persistedSession)

// every time the store updates, update the session stored in localStorage
store.subscribe(() => {
  saveSessionState(store.getState().session)
})

const roles = ['GUEST', 'USER', 'ADMIN', 'EMPLOYEE', 'STUDENT', 'FAMILYMEMBER']

function App() {
  return (
    <ApolloProvider client={client}>
      <StoreProvider store={store}>
        <ThemeProvider theme={theme}>
          <StylesProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <GlobalSnackbar />
              <Router history={history}>
                <ScrollReset />
                <GoogleAnalytics />
                <CookiesNotification />
                <AuthGuard roles={roles}>{renderRoutes(routes)}</AuthGuard>
              </Router>
            </MuiPickersUtilsProvider>
          </StylesProvider>
        </ThemeProvider>
      </StoreProvider>
    </ApolloProvider>
  )
}

export default App
