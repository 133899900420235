const {
  REACT_APP_NODE_ENV,
  REACT_APP_API_HOST,
  REACT_APP_LOGIN_REDIRECT,
  REACT_APP_S3_BUCKET,
  REACT_APP_S3_REGION,
  REACT_APP_TOKEN_NAME,
  REACT_APP_ID_NAME,
} = process.env

export const debug = REACT_APP_NODE_ENV !== 'production'

if (debug) {
  console.warn('Running in debug mode')
}

export const tokenName = REACT_APP_TOKEN_NAME || 'ardentoken'
export const idName = REACT_APP_ID_NAME || 'employeeId'

export const LOCAL_ARDENT_ACCOUNT_URL = 'http://localhost:3001/login'

export const loginRedirect =
  REACT_APP_LOGIN_REDIRECT ||
  (debug
    ? 'https://account-next.ardentlabs.io/login'
    : 'https://account.ardentlabs.io/login')

export const apiUrl =
  REACT_APP_API_HOST ||
  (debug
    ? 'https://ardent-api-next.ardentlabs.io'
    : 'https://ardent-api.ardentlabs.io')

const bucket = REACT_APP_S3_BUCKET || 'solve-app-dev'
const region = REACT_APP_S3_REGION || 'us-west-1'

export const s3Config = {
  bucket,
  region,
  url: `https://${bucket}.s3-${region}.amazonaws.com`,
}

export const graphAPIConfig = {
  // client-id of the application registered on AAD (https://aad.portal.azure.com/)
  clientId: '7064abaf-5cbe-4a5d-8fbd-6e9e093e2b60',
  scopes: ['user.read', 'calendars.readWrite'],
}
