import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Container, Grid, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  media: {
    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
}))

function UserFlows({ className, ...rest }) {
  const classes = useStyles()

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Container maxWidth="lg">
        <Grid alignItems="center" container justify="space-between">
          <Grid item lg={4} md={5} xs={12}>
            <Typography gutterBottom variant="h3">
              Path to Success
            </Typography>
            <Typography variant="subtitle2">
              Ardent Academy strives to support students throughout their
              academic career, offering an extensive College Counseling program
              that focuses on mentoring students and families to achieve college
              application success as early as the 7th grade.
            </Typography>
          </Grid>
          <Grid className={classes.media} item lg={4} md={6} xs={12}>
            <img alt="User flows" src="/images/presentation/gradhat.png" />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

UserFlows.propTypes = {
  className: PropTypes.string,
}

export default UserFlows
