/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react'
import { useLocation, matchPath } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import { useHistory } from 'react-router'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {
  Button,
  Drawer,
  Divider,
  Avatar,
  List,
  ListSubheader,
  Typography,
  Hidden,
  IconButton,
  Badge,
  Link,
  colors,
} from '@material-ui/core'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import MoreIcon from '@material-ui/icons/MoreVert'
import NavItem from 'src/components/NavItem'
import navConfig from './navConfig'
import { logout } from 'src/actions'
import { tokenName, idName } from '../../config'
import * as Cookies from 'js-cookie'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  navigation: {
    overflow: 'auto',
    padding: theme.spacing(0, 2, 2, 2),
    flexGrow: 1,
  },
  profile: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
  badgeDot: {
    height: 9,
    minWidth: 9,
  },
  onlineBadge: {
    backgroundColor: colors.green[600],
  },
  awayBadge: {
    backgroundColor: colors.orange[600],
  },
  busyBadge: {
    backgroundColor: colors.red[600],
  },
  offlineBadge: {
    backgroundColor: colors.grey[300],
  },
  avatar: {
    cursor: 'pointer',
    width: 40,
    height: 40,
  },
  details: {
    marginLeft: theme.spacing(2),
  },
  moreButton: {
    marginLeft: 'auto',
    color: colors.blueGrey[200],
  },
  logoutButton: {
    justifyContent: 'flex-start',
    textTransform: 'capitalize',
    padding: '10px 8px',
  },
  logoutIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1),
  },
}))

function renderNavItems({
  // eslint-disable-next-line react/prop-types
  items,
  subheader,
  key,
  isEmployee,
  ...rest
}) {
  return (
    <List key={key}>
      {subheader && <ListSubheader disableSticky>{subheader}</ListSubheader>}
      {/* eslint-disable-next-line react/prop-types */}
      {items.reduce(
        // eslint-disable-next-line no-use-before-define
        (acc, item) => reduceChildRoutes({ acc, item, isEmployee, ...rest }),
        []
      )}
    </List>
  )
}

function reduceChildRoutes({ acc, pathname, item, isEmployee, depth = 0 }) {
  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    })

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={item.href}
        label={item.label}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    )
  } else {
    if ((item.employeeRoute && isEmployee) || !item.employeeRoute) {
      acc.push(
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          key={item.href}
          label={item.label}
          title={item.title}
        />
      )
    }
  }

  return acc
}

function NavBar({ openMobile, onMobileClose, className, ...rest }) {
  const classes = useStyles()
  const location = useLocation()
  const dispatch = useDispatch()
  const session = useSelector(state => state.session)
  const history = useHistory()
  const mobileScreen = useMediaQuery('(max-width: 980px)')
  const [status, setStatus] = useState('online')

  const handleStatusToggle = () => {
    const statusSeq = {
      online: 'away',
      away: 'busy',
      busy: 'offline',
      offline: 'online',
    }

    setStatus(prevStatus => statusSeq[prevStatus])
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }

    // eslint-disable-next-line
  }, [location.pathname])

  const handleLogout = () => {
    Cookies.remove(tokenName)
    Cookies.remove(idName)

    dispatch(logout())
    history.push('/')
  }

  const content = (
    <div {...rest} className={clsx(classes.root, className)}>
      <nav className={classes.navigation}>
        {session.loggedIn &&
          navConfig.map(list =>
            renderNavItems({
              items: list.items,
              subheader: list.subheader,
              pathname: location.pathname,
              key: list.subheader,
              isEmployee: session.user.role === 'EMPLOYEE',
            })
          )}
        {session.loggedIn && mobileScreen && (
          <Button
            onClick={handleLogout}
            fullWidth
            className={classes.logoutButton}
          >
            <ExitToAppIcon className={classes.logoutIcon} />
            Logout
          </Button>
        )}
      </nav>
      <Divider className={classes.divider} />
      <div className={classes.profile}>
        <Badge
          overlap="circle"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          classes={{
            dot: classes.badgeDot,
            badge: clsx({
              [classes.badge]: true,
              [classes.onlineBadge]: status === 'online',
              [classes.awayBadge]: status === 'away',
              [classes.busyBadge]: status === 'busy',
              [classes.offlineBadge]: status === 'offline',
            }),
          }}
          variant="dot"
        >
          <Avatar
            alt="Person"
            onClick={handleStatusToggle}
            className={classes.avatar}
            src={session.user.avatar}
          />
        </Badge>
        <div className={classes.details}>
          <Link
            component={RouterLink}
            to="/profile/1/timeline"
            variant="h5"
            color="textPrimary"
            underline="none"
          >
            {`${session.user.first_name} ${session.user.last_name}`}
          </Link>
          <Typography variant="body2">{session.user.bio}</Typography>
        </div>
        <IconButton className={classes.moreButton} size="small">
          <MoreIcon />
        </IconButton>
      </div>
    </div>
  )

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.mobileDrawer,
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.desktopDrawer,
          }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  )
}

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
}

export default NavBar
