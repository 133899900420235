import { combineReducers } from 'redux'
import sessionReducer from './sessionReducer'
import snackBarReducer from './snackBarReducer'

const rootReducer = combineReducers({
  session: sessionReducer,
  snackbar: snackBarReducer,
})

export default rootReducer
