import { Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { useSelector, useDispatch } from 'react-redux'
import { setSnackbar } from 'src/actions'

function GlobalSnackbar() {
  const snackbarState = useSelector(state => state.snackbar)
  const dispatch = useDispatch()

  const handleSnackbarClose = () => {
    dispatch(setSnackbar(false, snackbarState.status, snackbarState.message))
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      autoHideDuration={2600}
      onClose={handleSnackbarClose}
      open={snackbarState.open}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={handleSnackbarClose}
        severity={snackbarState.status}
      >
        {snackbarState.message}
      </Alert>
    </Snackbar>
  )
}

export default GlobalSnackbar
