/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {
  AppBar,
  Badge,
  Button,
  IconButton,
  Toolbar,
  Hidden,
  Input,
  colors,
  Popper,
  Paper,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ClickAwayListener,
} from '@material-ui/core'
import LockIcon from '@material-ui/icons/LockOutlined'
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined'
import PeopleIcon from '@material-ui/icons/PeopleOutline'
import InputIcon from '@material-ui/icons/Input'
import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search'
import axios from 'src/utils/axios'
import NotificationsPopover from 'src/components/NotificationsPopover'
import PricingModal from 'src/components/PricingModal'
import ChatBar from './ChatBar'

import { logout } from 'src/actions'
import { useSelector } from 'react-redux'

import { withCookies } from 'react-cookie'
import {
  loginRedirect,
  tokenName,
  idName,
  LOCAL_ARDENT_ACCOUNT_URL,
} from '../../config'
import * as Cookies from 'js-cookie'

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1,
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit',
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit',
    },
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100,
  },
  searchPopperContent: {
    marginTop: theme.spacing(1),
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.common.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900],
    },
  },
  trialIcon: {
    marginRight: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  chatButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600],
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
}))

const popularSearches = [
  'Devias React Dashboard',
  'Devias',
  'Admin Pannel',
  'Project',
  'Pages',
]

function TopBar({ onOpenNavBarMobile, className, cookies, ...rest }) {
  const classes = useStyles()
  const mobileScreen = useMediaQuery('(max-width: 980px)')
  const xsmallScreen = useMediaQuery('(max-width: 321px)')
  const searchRef = useRef(null)
  const dispatch = useDispatch()
  const session = useSelector(state => state.session)
  const history = useHistory()
  const notificationsRef = useRef(null)
  const [openSearchPopover, setOpenSearchPopover] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [notifications, setNotifications] = useState([])
  const [openNotifications, setOpenNotifications] = useState(false)
  const [openChatBar, setOpenChatBar] = useState(false)
  const [pricingModalOpen, setPricingModalOpen] = useState(false)

  const handlePricingModalOpen = () => {
    setPricingModalOpen(true)
  }

  const handlePricingModalClose = () => {
    setPricingModalOpen(false)
  }

  const handleChatBarOpen = () => {
    setOpenChatBar(true)
  }

  const handleChatBarClose = () => {
    setOpenChatBar(false)
  }

  const handleNotificationsOpen = () => {
    setOpenNotifications(true)
  }

  const handleNotificationsClose = () => {
    setOpenNotifications(false)
  }

  const handleSearchChange = event => {
    setSearchValue(event.target.value)

    if (event.target.value) {
      if (!openSearchPopover) {
        setOpenSearchPopover(true)
      }
    } else {
      setOpenSearchPopover(false)
    }
  }

  const handleSearchPopverClose = () => {
    setOpenSearchPopover(false)
  }

  const handleLogout = () => {
    Cookies.remove(tokenName)
    Cookies.remove(idName)
    localStorage.removeItem('ardentSession')

    dispatch(logout())
    history.push('/')
  }

  useEffect(() => {
    let mounted = true

    const fetchNotifications = () => {
      axios.get('/api/account/notifications').then(response => {
        if (mounted) {
          setNotifications(response.data.notifications)
        }
      })
    }

    fetchNotifications()

    return () => {
      mounted = false
    }
  }, [])

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="primary">
      <Toolbar>
        <Hidden lgUp>
          {session.loggedIn && (
            <IconButton
              className={classes.menuButton}
              color="inherit"
              onClick={onOpenNavBarMobile}
            >
              <MenuIcon />
            </IconButton>
          )}
        </Hidden>
        <RouterLink to="/">
          <img
            alt="Logo"
            src="/images/logos/ardent-labs-logo.png"
            width={mobileScreen ? '130px' : '200px'}
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden smDown>
          <div className={classes.search} ref={searchRef}>
            <SearchIcon className={classes.searchIcon} />
            <Input
              className={classes.searchInput}
              disableUnderline
              onChange={handleSearchChange}
              placeholder="Search people &amp; places"
              value={searchValue}
            />
          </div>
          <Popper
            anchorEl={searchRef.current}
            className={classes.searchPopper}
            open={openSearchPopover}
            transition
          >
            <ClickAwayListener onClickAway={handleSearchPopverClose}>
              <Paper className={classes.searchPopperContent} elevation={3}>
                <List>
                  {popularSearches.map(search => (
                    <ListItem
                      button
                      key={search}
                      onClick={handleSearchPopverClose}
                    >
                      <ListItemIcon>
                        <SearchIcon />
                      </ListItemIcon>
                      <ListItemText primary={search} />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </ClickAwayListener>
          </Popper>
          <Button
            className={classes.trialButton}
            onClick={handlePricingModalOpen}
            variant="contained"
          >
            <LockIcon className={classes.trialIcon} />
            Trial expired
          </Button>
        </Hidden>
        {session.loggedIn && (
          <IconButton
            className={classes.chatButton}
            color="inherit"
            onClick={handleChatBarOpen}
          >
            <Badge badgeContent={6} color="secondary">
              <PeopleIcon />
            </Badge>
          </IconButton>
        )}
        <Hidden>
          {session.loggedIn && (
            <IconButton
              className={classes.notificationsButton}
              color="inherit"
              onClick={handleNotificationsOpen}
              ref={notificationsRef}
            >
              <Badge
                badgeContent={notifications.length}
                classes={{ badge: classes.notificationsBadge }}
                variant="dot"
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>
          )}
          {session.loggedIn ? (
            !mobileScreen && (
              <Button
                className={classes.logoutButton}
                color="inherit"
                onClick={handleLogout}
              >
                Logout
              </Button>
            )
          ) : (
            <Button
              className={classes.logoutButton}
              color="inherit"
              component={RouterLink}
              to="/auth/login"
            >
              <InputIcon className={classes.logoutIcon} />
              {!xsmallScreen && 'Login'}
            </Button>
          )}
        </Hidden>
      </Toolbar>
      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        onClose={handleNotificationsClose}
        open={openNotifications}
      />
      <PricingModal onClose={handlePricingModalClose} open={pricingModalOpen} />
      <ChatBar onClose={handleChatBarClose} open={openChatBar} />
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
}

export const redirect = props => {
  Cookies.remove(tokenName)
  Cookies.remove(idName)

  window.location.replace(
    (window.location.href.startsWith('http://localhost')
      ? LOCAL_ARDENT_ACCOUNT_URL
      : loginRedirect) + `?from=${encodeURIComponent(window.location.href)}`
  )
}

export default withCookies(TopBar)
