import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Container, Grid, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  media: {
    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
}))

function SourceFiles({ className, ...rest }) {
  const classes = useStyles()

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Container maxWidth="lg">
        <Grid alignItems="center" container justify="space-between">
          <Grid item lg={4} md={5} xs={12}>
            <Typography gutterBottom variant="h3">
              Successful Results
            </Typography>
            <Typography variant="subtitle2">
              Over the past 10 years, more than 90% of Ardent Counseling’s high
              school graduates have attended top 30 universities with the
              majority of them receiving at least one admission from an Ivy
              league school. Universities include UCLA, MIT, Columbia, USC, and
              much more!
            </Typography>
          </Grid>
          <Grid className={classes.media} item lg={4} md={6} xs={12}>
            <img
              alt="User flows"
              src="https://ardentacademy.com/images/ivy%20league%20schools.jpg"
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

SourceFiles.propTypes = {
  className: PropTypes.string,
}

export default SourceFiles
