import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { makeStyles } from '@material-ui/styles'
import { Card, CardHeader, CardContent, Divider } from '@material-ui/core'
import Chart from './Chart'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {},
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
  inner: {
    height: 375,
    minWidth: 500,
  },
  chart: {
    height: '100%',
  },
}))

function PerformanceOverTime({ className, ...rest }) {
  const classes = useStyles()
  const data = {
    thisYear: {
      data: [1, 3, 5, 7, 15, 20, 6, 25],
      labels: [
        '5th grade',
        '6th grade',
        '7th grade',
        '8th grade',
        '9th grade',
        '10th grade',
        '11th grade',
        '12th grade',
      ],
    },
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Student Stats" />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Chart
              className={classes.chart}
              data={data.thisYear.data}
              labels={data.thisYear.labels}
            />
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  )
}

PerformanceOverTime.propTypes = {
  className: PropTypes.string,
}

export default PerformanceOverTime
