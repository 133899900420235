import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useCookies } from 'react-cookie'

import PropTypes from 'prop-types'

import { idName } from 'src/config'

function AuthGuard({ roles, children }) {
  const session = useSelector(state => state.session)
  const [cookies] = useCookies([idName])
  const history = useHistory()

  useEffect(() => {
    if (!session.loggedIn || !cookies[idName]) {
      history.push('/presentation')
      return
    }

    if (session.loggedIn && !roles.includes(session.user.role)) {
      history.push('/errors/error-401')
    }
  }, [history, roles, session.loggedIn, session.user, cookies])

  return <>{children}</>
}

AuthGuard.propTypes = {
  children: PropTypes.node,
  roles: PropTypes.array.isRequired,
}

export default AuthGuard
